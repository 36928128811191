<template>
  <!-- 引入样式 -->
  <div class="sortProduct">
    <NavHeader />

    <div class="content_container">

      <!-- <el-divider content-position="left">

        <el-breadcrumb separator-class="el-icon-arrow-right ">
          <el-breadcrumb-item>
            <span
              class="hover"
              @click="all"
            >全部 </span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            @click="choseSort(sort)"
            v-if='sort.name'
          ><span
              class="hover"
              @click="choseSort(sort)"
            > {{sort.name}}</span> </el-breadcrumb-item>
          <el-breadcrumb-item v-if='searchData.brand_id'> 品牌：{{brand}} </el-breadcrumb-item>
        </el-breadcrumb>

      </el-divider> -->

      <div class="sort-list">
        <ol>
          <li
            class="item hover"
            v-for="(item, index) in goodList"
            :key="index"
          >
            <div class="item-img-wraper">
              <img
                class="item-img"
                :src="$config.baseUrl + item.files_path"
              @click="$util.toDetail(item.get_shop.shop_type, item.goods_id )"
              />
            </div>
            <p
              class="item-name"
              @click="$util.toDetail(item.get_shop.shop_type, item.goods_id )"
            >
              {{ item.goods_name }}
            </p>

            <div class="item-price">
              <p>活动价：￥{{ item.act_value }}</p>
              <p>原价：￥{{ item.goods_value }}</p>
            </div>

            <div class="shop-name">
              <span @click="$util.toShop(item.get_shop.shop_type, item.shop_id )">
                {{ item.shop_name }}
              </span>
            </div>
            <!-- <div class="car">
              <span><i class="el-icon-goods"></i>加入购物车</span>
            </div> -->
          </li>
        </ol>
      </div>
      <el-pagination
        @current-change="currentChange"
        :current-page.sync="searchData.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="searchData.limit"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  components: {
    NavHeader
  },
  data() {
    return {
      listDataSort: [],
      brand: undefined,
      cindex: undefined,
      cindex1: undefined,
      cindex2: undefined,
      idx: undefined,
      sort: { id: "", name: "" },

      searchData: {
        act_id: this.$route.query.id,
        cheacked: 1,
        cheacked2: 1,
        page: 1,
        limit: 10
      },
      total: 0,

      isErr: false,
      timer: null,

      car: false,
      num: "0",
      listIndex: 0,
      name: "",
      goodList: [],
      saleList: [],
      priceList: [],
      Category: [],
      children: [],
      child: [],
      navList: [
        {
          name: "全部"
        },
        {
          name: "销量"
        },
        {
          name: "价格"
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.currentChange();
  },
  methods: {
   
    

    currentChange() {
      this.$get("home/activityg/", this.searchData).then(res => {
        this.goodList = res.items.data;
        console.log("this.goodList", this.goodList);
        this.total = res.items.total;
        console.log("goodList", this.goodList);
      });
    }
  },
  created() {},
  watch: {
    "$route.query"() {
      this.searchData = {};
      this.searchData = Object.assign(this.searchData, this.$route.query);
      this.currentChange();
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.sortProduct {
  background: #fff;
}
::v-deep .el-collapse-item__header {
  display: inline-block;
}
::v-deep .el-collapse {
  display: inline-block;
  float: left;
}
.box {
  background: #f8f8f8;
  padding: 10px;
  margin-bottom: 5px;
}
.Category {
  li {
    font-size: 14px;

    display: inline-block;
    margin-right: 20px;
  }
  li:hover {
    color: red;
    cursor: pointer;
  }
}
.Category_c {
  font-size: 12px;
  padding: 10px;
  color: #888;
  p {
  }
  // .Category_cc{width: 50%; float: left;}
  .tit {
    font-weight: bold;
  }
  dl {
  }
  dd {
    display: inline-block;
    margin: 5px;
  }
}
.dropdown {
  p {
    font-weight: bold;
  }
  padding: 20px;
  dl {
    margin-bottom: 10px;
  }
  dd {
    color: #888;
    display: inline-block;
    margin: 5px 10px;
  }
  dd:hover {
    color: red;
    cursor: pointer;
  }
  ul {
    li {
      display: inline-block;
    }
  }
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.red {
  color: red !important;
}
.name {
}
.hover {
  cursor: pointer;
}
.listBrand li {
  display: inline-block;
  margin: 5px;
  margin-top: 0;
  color: #888;
}
.listBrand li:hover {
  color: red;
  cursor: pointer;
}

.filtrate {
  margin: 10px 0;
  padding: 8px 5px 0 5px;
  position: relative;
  z-index: 10;
  background: #faf9f9;
  color: #806f66;
  line-height: 20px;

  p {
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    margin-left: -1px;
    overflow: hidden;
    padding: 0 5px 0 5px;
    cursor: pointer;
    height: 22px;
    line-height: 20px;
    border: 1px solid #ccc;

    i {
      font-weight: 800;
    }
  }
}

.pointer {
  background: #fe6925;

  color: #fff;
  border-radius: 5px;
}
.topbar {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #757575;
  border-bottom: #ccc 1px solid;

  height: 50px;
  line-height: 50px;

  ul {
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    @include row;
    li {
      cursor: pointer;

      @include center;
      @include row;
      flex-wrap: nowrap;
      height: 30px;
      line-height: 30px;
      width: 80px;
      text-align: center;
      margin: 0 20px;
      @include center;
    }
  }
}
.Detail {
  background: #f5f5f5;
}
.sort-list {
  width: 100%;
  background: #fff;
  @include space-between;

  ul {
    background: #f0f0f0;
    width: 300px;

    li {
      padding: 10;
    }
  }
  ol {
    flex: 1;
    margin: 0 -5px;
    .item {
      background: #fff;
      box-sizing: border-box;
      padding: 10px;

      text-align: center;
      width: 226px;
      margin: 5px;
      border: 1px solid #eee;

      float: left;
      position: relative;

      .item-img-wraper {
        overflow: hidden;

        height: 190px;

        box-sizing: border-box;

        .item-img {
          width: 100%;
          height: 100%;
        }
      }
      .shop_name {
        height: 30px;
        text-align: left;
        line-height: 30px;
        @include space-between;

        p {
          color: #999;
          width: 80%;
          @include ellipsis;
        }
      }
      .item-name {
        text-align: left;
        padding: 5px 0;
        font-size: 14px;
        color: #757575;
        line-height: 20px;
        overflow: hidden;
        // height: 43px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-price {
        font-size: 24px !important;

        p:nth-child(1) {
          text-align: left;
          font-size: 14px !important;
          color: red;
        }
        p:nth-child(2) {
          text-align: left;
          text-decoration: line-through;
          color: #888;
        }
      }
      .shop-name {
        text-align: left;
        color: #999;
        @include ellipsis;

        line-height: 25px;
        height: 25px;
        cursor: pointer;
        font-size: 12px;
      }
      .car {
        text-align: left;
        margin-right: 10px;
        color: #999;
        i {
          font-size: 25px;
          color: #999;
        }
      }
    }
  }
  ol:after {
    content: "";
    width: 30%;
  }
}
.banner {
  width: 100%;
  img {
    width: 100%;
    height: 400px;
  }
}
.product-info {
  background: #fff;
  padding: 20px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}
</style>
 